"use client";
import { useEffect, useRef } from "react";

interface AdUnitProps {
  slot: string;
  className?: string;
  style?: React.CSSProperties;
  layout?: string;
  [key: `data-${string}`]: string | undefined;
}

declare global {
  interface Window {
    adsbygoogle: any[];
  }
}

export default function AdUnit({ slot, className = "", style, layout, ...restProps }: AdUnitProps) {
  const adRef = useRef<HTMLModElement>(null);

  useEffect(() => {
    // Initialize ads
    try {
      if (typeof window !== "undefined") {
        if (!window.adsbygoogle) {
          window.adsbygoogle = [];
        }
        if (adRef.current) {
          window.adsbygoogle.push({});
        }
      }
    } catch (err) {
      console.error("AdSense initialization error:", err);
    }

    // Cleanup
    return () => {
      if (adRef.current) {
        adRef.current.remove();
      }
    };
  }, []);

  return (
    <ins
      ref={adRef}
      className={`adsbygoogle ${className}`}
      style={{
        display: "block",
        overflow: "hidden",
        ...style,
      }}
      data-ad-client="ca-pub-4934188280561956"
      data-ad-slot={slot}
      data-ad-format="auto"
      data-full-width-responsive="true"
      data-ad-layout={layout || ""}
      {...restProps}
    />
  );
}


"use client";

import { usePathname } from "next/navigation";
import Header from "./Header";
import Footer from "./Footer";
import KlardaAPIBanner from "@/app/components/KlardaAPIBanner";
import React from "react";
import { useLanguageContext } from "@/context/LanguageContext";

const InsightLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <>
    <Header />
    <main className="w-full pt-[66px] lg:pt-[114px]">{children}</main>
  </>
);

const FullChartLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <main className="w-full h-screen">
    {children}
  </main>
);

const DefaultLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <>
    <Header />
    <main className="max-w-[1440px] mx-auto pt-20 lg:pt-32 px-4 md:px-8 lg:px-[20px]">{children}</main>
    <KlardaAPIBanner />
    <Footer />
  </>
);

const ContentWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const pathname = usePathname();
  const { language } = useLanguageContext();

  const isPageInsight = pathname === `/${language}/insight`;
  const isFullChart = pathname.includes('/full-chart');

  if (isFullChart) {
    return <FullChartLayout>{children}</FullChartLayout>;
  }

  return isPageInsight ? <InsightLayout>{children}</InsightLayout> : <DefaultLayout>{children}</DefaultLayout>;
};

export default ContentWrapper;

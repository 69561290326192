export default function RiseOutlineIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
        fill="url(#paint0_linear_2754_17088)"
      />
      <path
        d="M14.7806 5.00314L17.5344 4.67135L17.2029 7.42348L16.5445 6.76507L16.191 6.41165L15.8375 6.76494L10.6695 11.9291L8.81594 10.0756L8.46245 9.72213L8.10889 10.0756L2.94832 15.2343L2.55466 14.8389L8.46247 8.9292L10.318 10.7829L10.6715 11.1361L11.0249 10.7827L15.439 6.36866L15.7925 6.01511L15.439 5.66155L14.7806 5.00314ZM10.5366 12.0619C10.5367 12.0618 10.5368 12.0618 10.5369 12.0617L10.5367 12.0618L10.5366 12.0619ZM8.32953 8.7964L8.32971 8.79657C8.32965 8.79651 8.32959 8.79645 8.32953 8.7964Z"
        fill="white"
        stroke="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2754_17088"
          x1="10"
          y1="19.5"
          x2="14.2235"
          y2="0.549802"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#BD01FF" />
          <stop offset="1" stop-color="#0157FF" />
        </linearGradient>
      </defs>
    </svg>
  );
}

"use client";

import Image from "next/image";
import img1 from "@/asset/images/image.png";
import img2 from "@/asset/images/image_2.png";
import img2Mb from "@/asset/images/image_2_mb.png";
// import imgBlockChainForum from "@/asset/images/blockchain_forum_ads.png";
import { Button, Typography } from "antd/lib";
import Link from "next/link";
import classNames from "classnames";
import Slider from "react-slick";
import useResponsive from "../hooks/useResponsive";
import AdUnit from "./AdUnit";

export default function AdsBanner({
  isSmallSize = false,
  isOnlyAds = false,
}: {
  isSmallSize?: boolean;
  isOnlyAds?: boolean;
}) {
  // const screenSize = useResponsive();
  const sliderSettings = {
    dots: false,
    // infinite: true if 2 slides
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };

  // const bannerSlides = [
  //   // Slide 1
  //   <Link key="slide1" href="https://bubbles.klarda.com" target="_blank">
  //     <div
  //       className={classNames(
  //         "flex flex-col bg-no-repeat bg-center bg-contain text-white p-2 lg:p-4 rounded-xl",
  //         isSmallSize ? "gap-2 md:h-[170px]" : "gap-2 lg:gap-6 md:h-[220px] pt-2 lg:pt-8",
  //       )}
  //       style={{ backgroundImage: `url(${img1.src})` }}
  //     >
  //       <Typography.Text
  //         className={classNames(
  //           "text-[#170F49] max-w-[50%]",
  //           isSmallSize ? "text-[10px] lg:text-xl" : "text-xs lg:text-3xl lg:leading-8",
  //         )}
  //         strong
  //       >
  //         Live price visualization with Klarda bubble
  //       </Typography.Text>
  //       <Button
  //         size={screenSize < 1024 ? "small" : "large"}
  //         className={classNames(
  //           "mb-4 lg:mb-0 rounded-full w-fit lg:w-[120px] text-white font-bold bg-primary",
  //           isSmallSize ? "text-[10px] lg:text-base" : "text-[10px] lg:text-base",
  //         )}
  //       >
  //         Get started
  //       </Button>
  //     </div>
  //   </Link>,
  //   // Slide 2
  //   // <Link
  //   //   key="slide2"
  //   //   href="https://blockchain.forum/?utm_source=website&utm_medium=referral&utm_campaign=klarda"
  //   //   target="_blank"
  //   //   className="py-2 lg:py-0"
  //   // >
  //   //   <div
  //   //     className={classNames(
  //   //       "flex flex-col bg-no-repeat bg-center bg-contain text-white rounded-xl h-20 ",
  //   //       isSmallSize ? "gap-2 md:h-[170px]" : "gap-2 lg:gap-6 md:h-[220px]",
  //   //     )}
  //   //     style={{ backgroundImage: `url(${imgBlockChainForum.src})` }}
  //   //   ></div>
  //   // </Link>,
  // ];

  const bannerSlides = [
    // Slide 1
    <AdUnit slot="6799951910" key={1} />,

    // Slide 2
    // <AdUnit slot="6579594097" key={2} />,
  ];

  return (
    <div className={classNames("grid grid-cols-1 lg:grid-cols-12", isSmallSize ? "gap-2" : "gap-2 lg:gap-6")}>
      <div className={classNames(isOnlyAds ? "col-span-1 lg:col-span-12" : "col-span-1 lg:col-span-8")}>
        <Slider {...sliderSettings}>{bannerSlides}</Slider>
      </div>

      {!isOnlyAds && (
        <div className="col-span-1 lg:col-span-4  h-full">
          <AdUnit slot="9944419184" />
        </div>
        // <div className="col-span-1 lg:col-span-4 flex flex-col items-center justify-center h-full">
        //   <Link href="https://dashboard.klarda.com" target="_blank">
        //     <div className="lg:hidden">
        //       <Image src={img2Mb} alt="Mobile Banner" className="w-full h-auto rounded-xl" priority />
        //     </div>

        //     <div
        //       className={classNames(
        //         "hidden lg:flex flex-col bg-no-repeat bg-cover text-white p-4 rounded-xl h-full",
        //         isSmallSize ? "gap-2" : "gap-6 py-20",
        //       )}
        //       style={{ backgroundImage: `url(${img2.src})` }}
        //     >
        //       <Typography.Text
        //         className={classNames(
        //           "text-white max-w-[90%]",
        //           isSmallSize ? "text-xs lg:text-xl" : "text-2xl lg:text-3xl leading-8",
        //         )}
        //         strong
        //       >
        //         Manage portfolio, optimize your asset growing
        //       </Typography.Text>
        //       <Button size="large" className="rounded-full w-[170px] font-bold text-primary">
        //         Try it now for free
        //       </Button>
        //     </div>
        //   </Link>
        // </div>
      )}
    </div>
  );
}
